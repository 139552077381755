@tailwind base;
@tailwind components;
@tailwind utilities;
/* You can add global styles to this file, and also import other style files */
html, body {
  width: 100%;
  height: 100%;
  font-size: 16px;
}

body {
  background-color: #fff !important;
}

* {
  outline: none;
}

@font-face {
  font-family: ChalkboardSE-Regular;
  src: url('/assets/font/ChalkboardSE-Regular.woff') format('WOFF');
}

.k-16-9 {
  position: relative;
  width: 100%;
  height: fit-content;
  &:before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
  }
}

.k-full-fill {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.k-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}



.table-operations{
  margin-bottom: 1rem;
}
.operations{
  text-align: center;
}
.operations a{
  color: #1890ff!important;
  padding: 0 .5em;
  border-right: 1px solid #333;
}
.operations a:last-child{
  padding: 0 .5em;
  border-right: 0;
}

th, td{
  text-align: center!important;
}
.search-box {
  padding: 8px;
}

.search-box input {
  width: 188px;
  margin-bottom: 8px;
  display: block;
}

.search-box button {
  width: 90px;
}

.search-button {
  margin-right: 8px;
}
/* tool bar style */
.toolbar-operations {
  color: #1890ff!important;
  display: flex;
  padding: 1rem 1rem;
  background: #fff;
  border-radius: 1rem;
  .toolbar-main-title {
    flex: 1;
    line-height: 40px;
    color: #252525;
    font-size: 1.25rem;
    .toolbar-main-subtitle{
      font-size: .75rem;
    }
  }
  .toolbar-main-action{
    display: flex;
    flex: 0;
    margin-right: 0rem;
    button {
      border-radius: .5rem;
    }
  }
  .ant-btn .anticon {vertical-align: 0.125em;}

}
.ant-btn-dangerous.ant-btn-primary.delete-button{
  color: #fff;
  border-color: #ff4d4f;
  background: #ff4d4f;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, .12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, .045);
}
.v3 {
  .ant-table-container table > thead > tr:first-child th:first-child, .ant-table-container table > thead > tr:first-child th:last-child{
    border-radius: 0;
  }
  nz-form-label > label{
    color: #143684;
    font-size: 1rem;
    font-weight: 500;
  }
  nz-form-label.w-full > label{
    width: 100%
  }
  .ant-form-item-explain {
    text-align: left;
  }
  .anticon{
    vertical-align: 0.125em;
  }
  .ant-modal-header {
    border-bottom: 0;
    .ant-modal-title{
      color: #1890FF;
    }
  }
  .ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar, .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-ink-bar{
    left: 0;
    right: auto;
    width: 4px;
  }
}




.page-tab-main{
  .anticon{
    vertical-align: 0.125em;
  }
  nz-tabset[nztype=card]{
    > nz-tabs-nav{
      margin-bottom: 0!important;
      margin-left: 1rem!important;
      .ant-tabs-tab{
        border-bottom: 0!important;
        border-radius: 0.75rem .75rem 0 0!important;
        padding: 8.5px 2rem!important;
      }
      .ant-tabs-tab.ant-tabs-tab-active{
        background: #448ef7;

        .ant-tabs-tab-btn{
          color: #fff;
        }
      }
    }
  }

}
.page-main {
  flex: 1;
  border-radius: 1rem;
  padding: 1rem 0;
  margin-bottom: 1rem;
  background: #fff;
  //max-height: calc(100% - 48px - 56px - 72px);
  overflow-x: hidden;
  overflow-y: scroll;
  .page-main-header{
    height: 3rem;
    margin-left: 1rem;
    display: flex;
    .page-main-header-tools{
      flex: 1;
    }
    .page-main-header-actions{
      flex: 0;
    }
  }
}

.page-main-table {
  .ant-table-pagination{
    margin-right: 1rem;
  }
  .ant-table-pagination-right{
    justify-content: normal;

  }
  .ant-table-container{
    border-radius: 0;
    table{
      border-radius: 0;
    }
    .ant-table {
      border-radius: 0;
    }
  }

  ul{
    width: 100%;
    display: flex;
  }
  ul.ant-timeline{
    width: 100%;
    display: block;
  }
  .ant-table-pagination li.ant-pagination-total-text{
    flex: 1;
    margin-left: 1rem;
    color: #1890ff;
  }
  .ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
    .anticon{
      vertical-align: 0.125em;
    }
  }
  .ant-table-thead > tr > th{
    background: #eff5fe;
    color: #7c7c7c;
  }
  .ant-table-content > table {
    line-height: 1.5;
    thead > tr > th {
      background: #eff5fe;
      color: #7c7c7c;
      position: relative;
      //color: rgba(0, 0, 0, 0.85);
      font-weight: 500;
      text-align: center;
      //background: #fafafa;
      border-bottom: 1px solid #e8e8e8;
      transition: background 0.3s ease;
      padding: 16px;
    }
    .ant-table-container table > thead > tr:first-child th:first-child,
    .ant-table-container table > thead > tr:first-child th:last-child,
    thead > tr:first-child th:first-child,
    thead > tr:first-child th:last-child{
      border-radius: 0;
    }
    thead > tr > th:before{

    }
    thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
      position: absolute;
      top: 50%;
      right: 0;
      width: 1px;
      height: 1.6em;
      background-color: rgba(0, 0, 0, 0.06);
      transform: translateY(-50%);
      transition: background-color 0.3s;
      content: '';
    }
  }
}


.ant-btn-dangerous.ant-btn-primary {
  color: #fff;
  border-color: #ff4d4f;
  background: #ff4d4f;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, .12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, .045);
}
.ant-btn-dangerous.ant-btn-primary:focus, .ant-btn-dangerous.ant-btn-primary:hover {
  color: #fff;
  border-color: #ff7875;
  background: #ff7875;
}
.tw-modal-confirm-wrapper .tw-modal-confirm .ant-modal-confirm-btns{
  width: 100%;
  display: flex;
  margin-top: 2rem;
  button {
    flex: 1;
    height: 36px;
  }
}
.tw-modal-confirm-wrapper .ant-modal-confirm-title {
  text-align: center;
  color: #ff4d4f;
  margin-bottom: 1.5rem;
}
.tw-notice-wrapper .tw-notice{
  padding-bottom: 0;

  border-radius: 0.375rem;
  //display: flex;
  justify-content: center;
  align-items: center;
}

.tw-notice-wrapper .tw-notice{
  .ant-modal-content{
    border: 1px solid #b7eb8f;
    padding: 0px;
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    .ant-modal-confirm-body .ant-modal-confirm-content{
      margin-top: 2px;
    }
    .ant-modal-confirm-body-wrapper{
      display: flex;
    }
    .ant-modal-body{
      padding: 0px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.tw-notice-wrapper .tw-notice.success{
  .ant-modal-content {
    border: 1px solid #b7eb8f;
  }
}

.tw-notice-wrapper .tw-notice.error{
  .ant-modal-content {
    border: 1px solid #ffccc7;
  }
}

.tw-notice-wrapper .tw-notice.warning{
  .ant-modal-content {
    border: 1px solid #ffe58f;
  }
}
.page-header.teacher .ant-page-header-heading{
  padding-top: 1rem;
}
.page-header{
  border-radius: 1rem;
  background: #fff;
  overflow: hidden;
  padding: 0 0 1rem 0;
  overflow: hidden;
  margin-bottom: 1rem;
  .ant-descriptions-item-label, .ant-descriptions-item-content {
    font-weight: 300;
  }

  .ant-page-header-heading{
    padding: 0 1rem;
    background: #448ef7;
    height: 40px;
  }
  .ant-page-header-heading-left .ant-page-header-back-button{
    color: #fff;
  }
  nz-page-header{
    padding: 0;
  }
  nz-page-header-content{
    padding: 1rem;
  }
  .view-page-header{

  }
}

.main-text-color{
  color: #003789;
}

/*
// side menu
.ant-layout-sider{
  background: #fff;
}
// level 1 item select style
.ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub{
  background: #fff;
  color: #7c7c7c;
  padding: 0 12px;
}
// level 1 folder style
.ant-menu-dark .ant-menu-item:hover, .ant-menu-dark .ant-menu-item-active, .ant-menu-dark .ant-menu-submenu-active, .ant-menu-dark .ant-menu-submenu-open, .ant-menu-dark .ant-menu-submenu-selected, .ant-menu-dark .ant-menu-submenu-title:hover{
  background: #fff;
  color: #7c7c7c;
}
// level 2 select item style
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected{
  border-radius: 12px;
  color: #FFF;
  background: transparent;
  color: #1890ff;
}
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow, .ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow, .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow, .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow, .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow, .ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow{
  background: #7c7c7c;
}
// menu colspan icon
.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::after, .ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::after, .ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::after, .ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::before, .ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::before, .ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::before{
  color: #7c7c7c;
  background: #7c7c7c;
}
.ant-menu-dark .ant-menu-item, .ant-menu-dark .ant-menu-item-group-title, .ant-menu-dark .ant-menu-item > a, .ant-menu-dark .ant-menu-item > span > a{
  color: #7c7c7c;
}
 */
